/* Table of Contents
   - - - - - - - - -
	1. Global
	2. Standard Forms
	3. Header
	4. Home Page
	5. Inner Pages
	6. Footer
	7. Media Queries
	- - - - - - - - -
*/


/* ============
   1 = Global
   ============ */
body {font-family: 'Lato', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif; font-weight: normal; font-style: normal; font-size: 16px; line-height: 1.4; color: #5A5A5A; position: relative; -webkit-font-smoothing: antialiased; background: #fff; margin:0;}
body.login-page {background:url(../images/login_page_bg.jpg);}

.row {max-width:1170px;}
iframe {margin: 0 0 40px; border:0; width:100%;}
code { text-shadow: 0 1px white; line-height:1.3;	font-family: Consolas, Monaco, 'Andale Mono', monospace; font-weight:normal; white-space: pre-wrap; white-space: -moz-pre-wrap; white-space: -o-pre-wrap; background: #F5F2F0;display: block; padding: 12px; margin: 8px 0; color:#303038; border: 0; border-radius:6px;}
h1, h2, h3, h4, h5, h6 { font-family: 'Heebo', sans-serif, 'Lato', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif; font-weight: 700; color: #5A5A5A; text-rendering: optimizeLegibility; line-height: 1.1; margin-bottom: 12px; margin-top: 12px;  }
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small { font-size: 60%; color: #6f6f6f; line-height: 0; }
h1 { font-size: 44px; }
h2 { font-size: 34px; }
h3 { font-size: 27px; }
h4 { font-size: 23px; }
h5 { font-size: 17px; }
h6 { font-size: 14px; }
a { color: #b86662; text-decoration: none; line-height: inherit; }
a:hover { color: #37485C; text-decoration: none;}
p { font-family: inherit; font-weight: normal; line-height: 1.45; margin-bottom: 17px; font-size: 17px;}
hr { border: solid #eff0f4; border-width: 1px 0 0; clear: both; margin: 21px 0; height: 0; }
hr.small { border: double #37485c; border-width: 4px 0 0; clear: both;margin:0 auto; margin-top:30px; margin-bottom:20px; height: 0; max-width:150px; }
.spacing-30 {padding-bottom:30px;}
.spacing-top-50 {padding-bottom:50px;}

/* ==================
   2 = Standard Forms
   ================== */
form { margin: 0; padding:0;}
.row form .row { margin: 0 -6px; }
.row form .row .column, .row form .row .columns { padding: 0.4px; }
.row form .row.collapse { margin: 0; }
.row form .row.collapse .column, .row form .row.collapse .columns { padding: 0; }
label { color: #4d4d4d; cursor: pointer; display: block; font-weight: 500; margin-bottom: 3px; }
label.right { float: none; text-align: left; }
label.inline { line-height: 32px; margin: 0 0 12px 0; }
.prefix, .postfix { display: block; position: relative; z-index: 2; text-align: center; width: 100%; padding-top: 0; padding-bottom: 0; height: 32px; line-height: 31px; }
a.button.prefix, a.button.postfix { padding-left: 0; padding-right: 0; text-align: center; }
span.prefix, span.postfix { background: #f2f2f2; border: 1px solid #e8e8e8ccc; }
.prefix { right: 2px; -moz-border-radius-topright: 2px; -webkit-border-top-right-radius: 2px; border-top-right-radius: 2px; -moz-border-radius-bottomright: 2px; -webkit-border-bottom-right-radius: 2px; border-bottom-right-radius: 2px; overflow: hidden; }
.postfix { left: 2px; -moz-border-radius-topleft: 2px; -webkit-border-top-left-radius: 2px; border-top-left-radius: 2px; -moz-border-radius-bottomleft: 2px; -webkit-border-bottom-left-radius: 2px; border-bottom-left-radius: 2px; }
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], textarea { height: auto !important;background-color: white; font-family: inherit; border: 0; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #666; display: block; margin: 0; padding: 6px !important;  width: 100%; border-radius: 4px }
input[type="text"].oversize, input[type="password"].oversize, input[type="date"].oversize, input[type="datetime"].oversize, input[type="email"].oversize, input[type="number"].oversize, input[type="search"].oversize, input[type="tel"].oversize, input[type="time"].oversize, input[type="url"].oversize, textarea.oversize { font-size: 17px; padding: 4px 6px; }
input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, textarea:focus { background: #f6f6f6; border-color: #b3b3b3; }
input[type="text"][disabled], input[type="password"][disabled], input[type="date"][disabled], input[type="datetime"][disabled], input[type="email"][disabled], input[type="number"][disabled], input[type="search"][disabled], input[type="tel"][disabled], input[type="time"][disabled], input[type="url"][disabled], textarea[disabled] { background-color: #ddd; }
textarea { height: auto; }
select { background-color: white; font-family: inherit; border: 0; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #666; display: block; margin: 0; padding: 6px;  width: 100%; border-radius: 4px  }

/* ==================
   3 = Header
   ================== */
header {background:url(../images/header_bg.jpg) bottom center no-repeat; min-height:880px; position: relative;}
header .message {position: absolute; top:125px; left:1%; right:1%; margin:0 auto; text-align: center}
.message-intro {display:table-row;line-height:1.5em;font-size:2em;white-space:nowrap;margin-bottom:2em}
.message-intro p{font-size:inherit;overflow:hidden; display:table-cell;vertical-align:middle;padding-bottom:30px;color:#fff;font-size:22px;font-weight:400; letter-spacing: 2px}
.message-intro span.message-line{display:table-cell;width:50%}
.message-intro span.message-line{padding:0 10px}
.message-intro span.message-line:first-child{padding:0 0 30px 30px}
.message-intro span.message-line:last-child{padding:0 30px 30px 0}
.message-intro span.message-line:after{display:inline-block;width:100%;content:".";font-size:0;color:transparent;height:1px;background:#f7ebdf;vertical-align:middle;position:relative;top:0}
header .message h1 {color:#67484e; font-size:45px; font-weight:900; margin:8px 0 0; padding:0;}
header .message .button {background:transparent; border:1px solid #f7ebdf; color:#f7ebdf; margin-top:60px; font-weight:700;}
header .message .button:hover {background:#65464c; border-color:#65464c; text-decoration: none}
.top {background:#232e3f; background: rgba(35,46,63,.4); padding:0; width:100%; position:absolute; top:0; /*z-index:999999;*/}
.top.sticky {background:#232e3f; background: rgba(35,46,63,.7); padding:0; width:100%; position:fixed; z-index:99999999999999}
.logo {margin:15px 0 12px;}
header h2 {padding:45px 0 25px; font-size:42px; color:#fff; text-align: center; font-weight: 100}
#js-rotating {display: none}

header.alt-1 {background:url(../images/header_alt-1_bg.jpg) bottom center no-repeat; min-height:490px; position: relative}
header.alt-2 {background:url(../images/header_alt-2_bg.jpg) bottom center no-repeat; min-height:490px; position: relative}
header.alt-3 {background:url(../images/header_alt-3_bg.jpg) bottom center no-repeat; min-height:490px; position: relative}
header.alt-4 {background:url(../images/header_alt-4_bg.jpg) bottom center no-repeat; min-height:580px; position: relative}
header.alt-5 {background:url(../images/header_alt-5_bg.jpg) bottom center no-repeat; min-height:490px; position: relative}
header.login {background:transparent; min-height:1px;}

header.alt-3-short {min-height: 210px;}
header.alt-1 .message h1, header.alt-2 .message h1, header.alt-4 .message h1 {color: #D7D7D7}


/* Video Header */
header.video {background:#F5E8DF;  min-height:1px; }
header.video .message {top:35%; z-index:2;}
header.video .message h1 {color:#fff;}
header.video .message .button:hover {background:#C66C66;}
#video-container {width: 100%;    display: block;  position: relative; margin:0; padding:0; }
#video-container:after {  content: "";    background: url(../images/video_bg_pattern.png) repeat;    top: 0;    left: 0;    bottom: 5px;    right: 0;    position: absolute;    z-index: 1;   }
video { width: 100%;}

/* Slider Header */
header.theslider {background:#F5E8DF;  min-height:1px; }
#slider-container {width: 100%;    display: block;  position: relative; margin:0; padding:0; }
#home-slider .item {width:100%; max-width:100%; margin:0; padding:0;}
header.theslider .owl-controls {position:absolute;   bottom:15%;  right:1%; left:1%; z-index:99;}
header.theslider .owl-theme .owl-controls .owl-buttons div{padding: 5px 10px;-webkit-border-radius: 0; margin:0;  -moz-border-radius: 0;   border-radius: 0;   background: #C66C66; }
header.theslider .owl-theme .owl-controls .owl-buttons .owl-prev{margin-left:1px; }
header.theslider .owl-theme .owl-controls .owl-buttons i { font-size:24px; font-style: normal}
.slidercaption { position: absolute;top: 26%; left: 1%; right:1%; text-align: center; z-index:999;}
.slidercaption h3 { color:#fff; font-weight:700; font-size:35px; letter-spacing: .15em; margin-bottom:32px;}
.slidercaption p {display:inline-block; font-size:19px; color:#fff; background: #C66C66 url(../images/slider_text_bg.png) repeat; max-width:55%; letter-spacing: 0; padding:25px; -webkit-box-shadow: 7px 0 0px 7px rgba(55, 72, 92, 0.25);
-moz-box-shadow:     7px 0 0px 7px rgba(55, 72, 92, 0.25);
box-shadow:          7px 0 0px 7px rgba(55, 72, 92, 0.25);}
.slidercaption .button {background:transparent; border:1px solid #f7ebdf; color:#f7ebdf; margin-top:20px; font-weight:700; letter-spacing: .15em}
.slidercaption .button:hover {background:#C66C66; border-color:#C66C66; text-decoration: none}
.clouds {position: absolute; bottom:0;  z-index:4; max-width:100%;}

/* Navigation  ---------------------- */
nav.desktop-menu {  padding: 0; float:left; text-align: right; }
.slicknav_menu, .mobile-menu { display:none;  }

/*** Superfish Essential Styles ***/
.sf-menu, .sf-menu * { overflow: visible; margin: 0; padding: 0; list-style: none; z-index: 1000; }
.sf-menu { line-height: 1.0; float: right; margin-bottom: 0; }
.sf-menu ul { z-index: 1000; position: absolute; top: -999em; padding: 0; overflow: visible !important; }
.sf-menu ul li { display: block; width: 100% !important; text-align: right; padding:4px 0; border-bottom: 1px solid #40546a;margin-right:0!important; }
.sf-menu ul li:last-child { border-bottom: 0; -webkit-border-bottom-right-radius: 5px;-webkit-border-bottom-left-radius: 5px;-moz-border-radius-bottomright: 5px;-moz-border-radius-bottomleft: 5px;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;}
.sf-menu ul li a {  border:none;  -webkit-border-radius: 0!important;-moz-border-radius:  0!important;border-radius:  0!important;}
.sf-menu ul li:hover, .sf-menu ul li a:hover { background: #40546a !important; }
.sf-menu li { float: right; position: relative; text-decoration:none; text-align: center;padding:26px 0 26px; margin-right:10px; }
.sf-menu li:first-child { margin-right:0; }
.sf-menu a {  display: block; position: relative; font-size:14px; letter-spacing:.07em; text-transform: uppercase; font-weight:400; padding:6px 15px 8px; color:#fff; }
.sf-menu > li > a {display: block; }
.sf-menu li li a { font-size:11px; color:#fff; padding:7px 10px 5px; }
.sf-menu li a:hover { background: #37485C; text-decoration: none; -webkit-transition:all .3s;-moz-transition:all .3s;-o-transition:all .3s;-ms-transition:all .3s;transition:all .3s; -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px;}
.sf-menu li li a:hover { border-bottom:0; text-decoration: none}
.sf-menu li.current-menu-item a, .sf-menu li.current-menu-parent a, .sf-menu li.current_page_parent a { background: #37485C; color:#fff; -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px;}
.sf-menu li li.current-menu-item a, .sf-menu li.current-menu-parent li a, .sf-menu li.current-menu-item ul.sub-menu li a { background: #898a8d; color:#fff; }
.sf-menu li li.current-menu-item a:hover, .sf-menu li.current-menu-parent li a:hover, .sf-menu li.current-menu-item ul.sub-menu li a:hover { background: #3ec7cf; color:#fff;}
.sf-menu li:hover ul,.sf-menu li.sfHover ul { left: 0; top: 80px; z-index:999999;  width:175px;  -webkit-animation-duration: .3s;  -webkit-animation-timing-function: ease;  -webkit-animation-fill-mode: both;  -webkit-animation-name: fadeInLeft;  -moz-animation-duration: .3s;  -moz-animation-timing-function: ease;  -moz-animation-fill-mode: both;  -moz-animation-name: fadeInLeft;  animation-duration: .3s;  animation-timing-function: ease;  animation-fill-mode: both;  animation-name: fadeInLeft;}
ul.sf-menu li:hover li ul, ul.sf-menu li.sfHover li ul { top: -999em; }
ul.sf-menu li li:hover ul, ul.sf-menu li li.sfHover ul { right: 175px; top: 0; -webkit-border-radius: 0!important;-moz-border-radius:  0!important;border-radius:  0!important; }
ul.sf-menu li li:hover ul li:last-child, ul.sf-menu li li.sfHover ul li:last-child {-webkit-border-bottom-right-radius: 5px;-webkit-border-bottom-left-radius: 5px;-moz-border-radius-bottomright: 5px;-moz-border-radius-bottomleft: 5px;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;}
ul.sf-menu li li:hover li ul, ul.sf-menu li li.sfHover li ul { top: -999em; }
ul.sf-menu li li li:hover ul, ul.sf-menu li li li.sfHover ul { right: 175px; top: 0; -webkit-border-radius: 0!important;-moz-border-radius:  0!important;border-radius:  0!important;}
.sf-menu li:hover { outline: 0; }
.sf-menu li li a:hover { color:#fff !important; -webkit-transition:none !important;-moz-transition:none!important;-o-transition:none!important;-ms-transition:none!important;transition:none!important;}
.sf-menu > li:hover { border:none !important; background: none !important; }
.alt-nav { display:none; }
.sf-menu ul {background:#37485C; -webkit-border-bottom-right-radius: 5px;-webkit-border-bottom-left-radius: 5px;-moz-border-radius-bottomright: 5px;-moz-border-radius-bottomleft: 5px;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px; }
.sf-menu ul:after {  bottom: 100%;  border: solid transparent;  content: " ";  height: 0;  width: 0;  position: absolute;  pointer-events: none;}
.sf-menu ul:after {  border-color: rgba(127, 140, 141, 0);  border-bottom-color: #37485C;  border-width: 10px;  left: 17%;  margin-left: -10px;}
.sf-menu ul li ul:after {  bottom: 100%;  border: transparent; }
.sf-menu ul li ul:after {  border:0;}
.slicknav_nav a:hover {text-decoration: none!important}

/* ==================
   4 = Home Page
   ================== */

/* Features */
.features {padding:75px 0; background:#F5E8DF; overflow:hidden;}
.features.servers {padding:40px 0 0; background:#fff;}
.features.premium-servers {padding:50px 0; background:#535761;}
.features i {margin-bottom:10px; font-size:55px; color:#C5645B;}
.features h2 {font-weight:900;text-align: center; margin-bottom: 12px}
.features.premium-servers h2 {color:#d7d7d7;}
.features.premium-servers p {color:#ccc;}
.features p {text-align: center;}
.features ul {margin-top:60px;}
.features ul li {text-align: center; padding:50px 20px 20px; border:1px solid #E0E0E0; border-right:0; border-top:0; min-height:230px;}
.features ul li:nth-child(3n+3) { border-left:0;}
.features ul li:nth-last-child(-n+3) {border-bottom:0;}
.features ul li h3 {margin-bottom:12px; font-size:21px;}

/* Call to Action */
.calltoaction {padding:50px 0; background:#222D3A; overflow:hidden;}
.calltoaction .longshadow { text-shadow: 1px 1px rgb(31, 41, 52), 2px 2px rgb(31, 41, 52), 3px 3px rgb(31, 41, 52), 4px 4px rgb(31, 41, 52), 5px 5px rgb(31, 41, 52), 6px 6px rgb(31, 41, 52), 7px 7px rgb(31, 41, 52), 8px 8px rgb(31, 41, 52), 9px 9px rgb(31, 41, 52), 10px 10px rgb(31, 41, 52), 11px 11px rgb(31, 41, 52), 12px 12px rgb(31, 41, 52), 13px 13px rgb(31, 41, 52), 14px 14px rgb(31, 41, 52), 15px 15px rgb(31, 41, 52), 16px 16px rgb(31, 41, 52), 17px 17px rgb(31, 41, 52), 18px 18px rgb(31, 41, 52), 19px 19px rgb(31, 41, 52), 20px 20px rgb(31, 41, 52), 21px 21px rgb(31, 41, 52), 22px 22px rgb(31, 41, 52), 23px 23px rgb(31, 41, 53), 24px 24px rgb(31, 41, 53), 25px 25px rgb(31, 42, 53), 26px 26px rgb(31, 42, 53), 27px 27px rgb(31, 42, 53), 28px 28px rgb(32, 42, 54), 29px 29px rgb(32, 42, 54), 30px 30px rgb(32, 42, 54), 31px 31px rgb(32, 42, 54), 32px 32px rgb(32, 43, 55), 33px 33px rgb(32, 43, 55), 34px 34px rgb(32, 43, 55), 35px 35px rgb(32, 43, 55), 36px 36px rgb(32, 43, 55), 37px 37px rgb(33, 43, 56), 38px 38px rgb(33, 43, 56), 39px 39px rgb(33, 44, 56), 40px 40px rgb(33, 44, 56), 41px 41px rgb(33, 44, 56), 42px 42px rgb(33, 44, 57), 43px 43px rgb(33, 44, 57), 44px 44px rgb(33, 44, 57), 45px 45px rgb(33, 44, 57), 46px 46px rgb(34, 45, 58);  height: 100%;  width: 100%;  font-size: 64px;  text-align: center;  color:#37485c;  font-weight:900;}
.calltoactioninfo {margin-top:40px; border-top:4px double #37485c; padding:40px 0 0; text-align: center; }
.calltoaction h2 {font-weight:900;text-align: center; margin:0; padding:0; color:#37485c; font-size:57px;  }
.calltoaction h2 span {color:#C5645B;  }
.calltoaction h3 {font-weight:300;text-align: center;  margin:15px 0 0; padding:0; color:#C5645B; font-size:22px; }
.calltoactioninfo .button {background:#C5645B; color:#fff; margin-top:30px; font-weight:700; float:none;}
.calltoactioninfo .button:hover {background:#37485c; text-decoration: none}

/* Pricing Tables - Boxes */
.pricingboxes {padding:50px 0; overflow: hidden }
.pricingboxes h2 {font-weight:900;text-align: center; margin-bottom: 12px}
.pricingboxes p {text-align: center;}
.pricingboxes .title { font-family: 'Lato', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;  -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; background: #65454c;  padding: 12px 0;    text-align: center; letter-spacing: .07em;   color: #fff;    font-weight: 800;    font-size: 17px; margin-bottom:15px; position: relative; }
.pricingboxes .title:after { top: 100%; right: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(101, 69, 76, 0); border-top-color: #65454c; border-width: 8px; margin-right: -8px; }
.pricing-table {  border: 0;  margin: 0 0 1.25em 0;padding:0;  }
.pricing-table * {    list-style: none;    line-height: 1; }
.pricing-table .price {  font-family: 'Lato', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif; background: #b86662; text-align: center;  padding:12px 0 18px;    color: #EAEAEA;    font-weight: 900;    font-size: 15px; }
.pricing-table .price span {    color: #EAEAEA;    font-weight: 900;    font-size: 53px; display: block; padding:10px 0; text-shadow: 1px 1px rgb(166, 92, 88), 2px 2px rgb(166, 92, 88), 3px 3px rgb(166, 92, 88), 4px 4px rgb(166, 92, 88), 5px 5px rgb(166, 92, 88), 6px 6px rgb(166, 92, 88), 7px 7px rgb(166, 92, 88), 8px 8px rgb(166, 92, 88), 9px 9px rgb(166, 92, 88), 10px 10px rgb(166, 92, 88), 11px 11px rgb(166, 92, 88), 12px 12px rgb(166, 92, 88), 13px 13px rgb(167, 92, 88), 14px 14px rgb(168, 93, 89), 15px 15px rgb(169, 93, 89), 16px 16px rgb(170, 94, 90), 17px 17px rgb(171, 94, 90), 18px 18px rgb(172, 95, 91), 19px 19px rgb(173, 95, 91), 20px 20px rgb(174, 96, 92), 21px 21px rgb(175, 97, 93), 22px 22px rgb(176, 97, 93), 23px 23px rgb(177, 98, 94), 24px 24px rgb(178, 98, 94), 25px 25px rgb(179, 99, 95), 26px 26px rgb(180, 99, 95), 27px 27px rgb(181, 100, 96), 28px 28px rgb(182, 100, 96), 29px 29px rgb(183, 101, 97), 30px 30px rgb(184, 102, 98);  background-color: rgb(184, 102, 98); }
.pricing-table .description { background: #b86662; text-align: center; padding:32px 0;    color: #fff;    font-size: 17px;   font-weight: normal;    line-height: 1.4; border-bottom: solid 1px #a85d59;  -webkit-border-top-left-radius: 8px;-webkit-border-top-right-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-topright: 8px;border-top-left-radius: 8px;border-top-right-radius: 8px;}
.pricing-table .bullet-item {    background-color: #b86662;    padding: 0.9375em;    text-align: center;    color: #fff;    font-size: 14px;    font-weight: normal;    border-bottom: solid 1px #a85d59; }
.pricing-table .cta-button {  background: #b86662;    text-align: center;    padding: 8px 0; -webkit-border-bottom-right-radius: 8px;-webkit-border-bottom-left-radius: 8px;-moz-border-radius-bottomright: 8px;-moz-border-radius-bottomleft: 8px;border-bottom-right-radius: 8px;border-bottom-left-radius: 8px; }
.pricing-table .cta-button p {  padding: 14px 0 8px; }
.pricing-table .cta-button span a {color:#fff; font-size:16px; font-weight:800; background: #65454c; padding:9px 27px; border-radius: 6px}
.pricing-table .cta-button span a:hover {background: #37485c; text-decoration: none;}

.pricingboxes-comparison {padding:0 0 50px; overflow: hidden; margin: 0 10px; }
.pricingboxes-comparison h2 {font-weight:900;text-align: center; margin-bottom: 12px}
.pricingboxes-comparison p {text-align: center;}

.pricingboxes-comparison .title-alt { font-family: 'Lato', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;  background: #65454c;  padding: 12px 0;    text-align: center; letter-spacing: .07em;   color: #fff;    font-weight: 800;    font-size: 17px; margin-bottom:15px; position: relative; }
.pricingboxes-comparison .title-alt:after { top: 100%; right: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(101, 69, 76, 0); border-top-color: #65454c; border-width: 8px; margin-right: -8px; }
.pricingboxes-comparison .title-features { font-family: 'Lato', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;  background: #b86662;  padding: 12px 0;    text-align: center; letter-spacing: .07em;   color: #fff;    font-weight: 800;    font-size: 17px; margin-bottom:15px; position: relative; }
.pricingboxes-comparison .title-features:after { top: 100%; right: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(101, 69, 76, 0); border-top-color: #b86662; border-width: 8px; margin-left: -8px; }
.pricing-table.alter .description {border-radius: 0;}
.pricing-table.alter.features .bullet-item {  background-color: #E5E5E5; text-align: right;color: #333; font-size:14px; border-bottom: solid 1px #D7D7D7; }
.pricing-table.alter.features .bullet-item a { color:#666; cursor: pointer;}
.has-tip { border:none!important; padding:0!important; margin:0!important; font-weight: 400!important}
.has-tip:hover { color:#000;}
.pricing-table.alter .bullet-item i { font-size: 12px }
.pricing-table.alter .bullet-item i.fa-check { color:#65454C; }
.pricing-table.alter .bullet-item i.fa-times { color:#FEB762; }
.pricing-table.alter .price { padding:14px 0 20px; }
.pricing-table.alter .price span {  font-size: 33px; }
.pricing-table.alter .price span sub {  font-size: 14px; padding-right:2px; font-weight: 400; text-shadow:none; top:0px; vertical-align: baseline;   position: relative;}
.pricing-table.alter .cta-button { background: #65454C; padding: 4px 0; border-radius: 0;}
.pricing-table.alter .cta-button p {  padding: 14px 0 2px; }
.pricing-table.alter .cta-button span a {font-size:14px; background: #b86662; padding:5px 17px; border-radius: 6px;}
.pricing-table.alter .cta-button span a:hover {background: #37485c; text-decoration: none;}


/* Testimonials */
.testimonials {padding:50px 0; background:#222D3A; margin-top:45px;}
.testimonials h2 {font-weight:900;text-align: center; margin: 28px 0 12px; color:#fff; font-size:44px;}
.testimonials h2 span {color:#B86662;  }
.testimonials i {color:#B86662; font-size:56px;}
.testimonials .circle {border-radius: 50%; overflow:hidden; width: 104px;	height: 104px; background:#37485c; padding-top:26px; margin:0 auto; margin-top:-104px; position:relative; z-index:99999; text-align: center; }
.owl-carousel .item {margin:0 3px;}
.testimonial-content  {margin-top:-12px;  padding:20px 0; text-align: center; background: #37485c; -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; }
.testimonial-content p {color:#fff; padding:25px 60px 15px; margin-bottom:0; text-align: right;}
.testimonial-content p:before { content: "\f10d";  font-family: FontAwesome; font-size:18px; padding-left:12px; margin-bottom:10px; color:#fff; color: rgba(255,255,255,.1);}
.testimonial-content p:after { content: "\f10e";  font-family: FontAwesome; font-size:18px; padding-right:12px; margin-bottom:10px; color:#fff; color: rgba(255,255,255,.1);}
.whoclient {text-align: center; margin-top:30px; z-index:9999;}
.whoclient span {font-size:17px; position: relative; color:#fff; margin:0 auto; background: #000; background: rgba(0,0,0,.4); padding:7px 14px; -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; }
.whoclient span:after { top: 100%; right: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(0, 0, 0, 0); border-top-color: #000000; border-top-color:rgba(0,0,0,.4); border-width: 6px; margin-right: -6px; }
.whoclient span a {color:#C5645B; }
.whoclient span a:hover {color: #37485c; text-decoration: none }
.testimonialimg {text-align: center;}
.testimonialimg img { width: 104px; margin-top:12px;  height: 104px;  border-radius: 50px;  -webkit-border-radius:50px;  -moz-border-radius: 50px;  box-shadow: 1px 1px rgb(50, 65, 83), 2px 2px rgb(50, 65, 83), 3px 3px rgb(50, 65, 83), 4px 4px rgb(50, 65, 83), 5px 5px rgb(50, 65, 83), 6px 6px rgb(50, 65, 83), 7px 7px rgb(50, 65, 83), 8px 8px rgb(50, 65, 83), 9px 9px rgb(50, 65, 83), 10px 10px rgb(50, 65, 83), 11px 11px rgb(50, 65, 83), 12px 12px rgb(50, 65, 83), 13px 13px rgb(50, 65, 83), 14px 14px rgb(50, 65, 83), 15px 15px rgb(50, 65, 83), 16px 16px rgb(50, 65, 83), 17px 17px rgb(50, 65, 83), 18px 18px rgb(50, 65, 83), 19px 19px rgb(50, 65, 83), 20px 20px rgb(50, 65, 83), 21px 21px rgb(50, 65, 83), 22px 22px rgb(50, 65, 83), 23px 23px rgb(50, 65, 83), 24px 24px rgb(50, 65, 83), 25px 25px rgb(50, 65, 83), 26px 26px rgb(50, 65, 83), 27px 27px rgb(50, 65, 84), 28px 28px rgb(50, 65, 84), 29px 29px rgb(50, 66, 84), 30px 30px rgb(50, 66, 84), 31px 31px rgb(51, 66, 85), 32px 32px rgb(51, 66, 85), 33px 33px rgb(51, 66, 85), 34px 34px rgb(51, 67, 85), 35px 35px rgb(51, 67, 86), 36px 36px rgb(51, 67, 86), 37px 37px rgb(51, 67, 86), 38px 38px rgb(52, 67, 86), 39px 39px rgb(52, 68, 87), 40px 40px rgb(52, 68, 87), 41px 41px rgb(52, 68, 87), 42px 42px rgb(52, 68, 87), 43px 43px rgb(52, 68, 88), 44px 44px rgb(52, 69, 88), 45px 45px rgb(53, 69, 88), 46px 46px rgb(53, 69, 88), 47px 47px rgb(53, 69, 89), 48px 48px rgb(53, 69, 89), 49px 49px rgb(53, 70, 89), 50px 50px rgb(53, 70, 89), 51px 51px rgb(53, 70, 90), 52px 52px rgb(54, 70, 90), 53px 53px rgb(54, 70, 90), 54px 54px rgb(54, 71, 90), 55px 55px rgb(54, 71, 91), 56px 56px rgb(54, 71, 91), 57px 57px rgb(54, 71, 91), 58px 58px rgb(54, 71, 91), 59px 59px rgb(55, 72, 92);}

/* Monitoring */
.monitoring {padding:50px 0 0; overflow:hidden; background: #fafafa url(../images/monitoring_bg.jpg) repeat-x center bottom;}
.monitoring h2 {font-weight:900;text-align: center; margin-bottom: 12px;}

/* ==================
   5 = Inner Pages
   ================== */

/* shared hosting - features */
.sharedfeatures-odd {padding:50px 0; overflow:hidden; background:#F8F8F8;}
.sharedfeatures-odd h2, .sharedfeatures-even h2  {font-weight:900;margin-bottom: 12px; font-size:23px;}
.sharedfeatures-odd i {color:#B86662; font-size:100px;}
.sharedfeatures-odd .circle {border-radius: 50%; overflow:hidden; width: 200px;	height: 200px; background:#37485c; padding-top:50px; margin:0 auto; position:relative; z-index:99999; text-align: center; }
.sharedfeatures-even {padding:50px 0; overflow:hidden; background:#fff;}
.sharedfeatures-even i {color:#DBDBDB; font-size:100px; }
.sharedfeatures-even .circle {border-radius: 50%; overflow:hidden; width: 200px;	height: 200px; background:#B86662; padding-top:50px; margin:0 auto; position:relative; z-index:99999; text-align: center; }

/* cloud vps - order steps */
.vps-order-steps {padding:0; overflow: hidden; margin: 0 10px;}
.vps-order-steps h2 {font-weight:900;text-align: center; margin-bottom: 12px}
.vps-order-steps h3 {font-weight:900;font-size:22px;}
.vps-order-steps h3.active {color:#3DB39E;}
.vps-order-steps p {text-align: center;}
.vps-order-steps img {margin:0 auto;}
.vps-order-steps .order-step { height: 40px;    margin: 25px 0 20px;}
.vps-order-steps .order-step .order-circle {    position: relative;    text-align: center;}
.vps-order-steps .order-step .order-circle .line {    position: absolute;    width: 100%;    top: 50%;    margin-top: -1px;    height: 3px;    background: #C3C3C3;    z-index: 1;}
.vps-order-steps .order-step .order-circle .line.left-side {    width: 50%;    left: 0;}
.vps-order-steps .order-step .order-circle .line.right-side {    width: 50%;    right: 0;}
.vps-order-steps .order-step .order-circle span {    position: relative;    display: inline-block;    vertical-align: middle;    width: 40px;    height: 40px;    line-height: 40px;    text-align: center;    -webkit-border-radius: 1000px;    -moz-border-radius: 1000px;    -ms-border-radius: 1000px;    -o-border-radius: 1000px;    border-radius: 1000px;    color: #fff;    font-weight:900;    background:#C3C3C3;    border:1px dotted #C3C3C3;    z-index: 10;}
.vps-order-steps .order-step .order-circle span.active {   background:#3DB39E;    border:1px dotted #3DB39E;}
.vps-order-steps .order-step .order-circle span.left-side {    margin-left: 15px;}
.vps-order-steps .order-step .order-circle span.right-side {    margin-right: 15px;}

/* dedicated servers */
table.responsive {min-width:100%; margin-top:45px; overflow:hidden; border:0!important; box-shadow: 1px 1px rgb(75, 78, 87), 2px 2px rgb(75, 78, 87), 3px 3px rgb(75, 78, 87), 4px 4px rgb(75, 78, 87), 5px 5px rgb(75, 78, 87), 6px 6px rgb(75, 78, 87), 7px 7px rgb(75, 78, 88), 8px 8px rgb(76, 79, 88), 9px 9px rgb(76, 79, 88), 10px 10px rgb(76, 79, 88), 11px 11px rgb(76, 79, 88), 12px 12px rgb(76, 79, 88), 13px 13px rgb(76, 79, 89), 14px 14px rgb(76, 79, 89), 15px 15px rgb(76, 80, 89), 16px 16px rgb(77, 80, 89), 17px 17px rgb(77, 80, 89), 18px 18px rgb(77, 80, 89), 19px 19px rgb(77, 80, 89), 20px 20px rgb(77, 80, 90), 21px 21px rgb(77, 80, 90), 22px 22px rgb(77, 81, 90), 23px 23px rgb(77, 81, 90), 24px 24px rgb(78, 81, 90), 25px 25px rgb(78, 81, 90), 26px 26px rgb(78, 81, 91), 27px 27px rgb(78, 81, 91), 28px 28px rgb(78, 81, 91), 29px 29px rgb(78, 82, 91), 30px 30px rgb(78, 82, 91), 31px 31px rgb(78, 82, 91), 32px 32px rgb(79, 82, 92), 33px 33px rgb(79, 82, 92), 34px 34px rgb(79, 82, 92), 35px 35px rgb(79, 82, 92), 36px 36px rgb(79, 83, 92), 37px 37px rgb(79, 83, 92), 38px 38px rgb(79, 83, 92), 39px 39px rgb(79, 83, 93), 40px 40px rgb(80, 83, 93), 41px 41px rgb(80, 83, 93), 42px 42px rgb(80, 83, 93), 43px 43px rgb(80, 84, 93), 44px 44px rgb(80, 84, 93), 45px 45px rgb(80, 84, 94), 46px 46px rgb(80, 84, 94), 47px 47px rgb(80, 84, 94), 48px 48px rgb(81, 84, 94), 49px 49px rgb(81, 84, 94), 50px 50px rgb(81, 85, 94), 51px 51px rgb(81, 85, 94), 52px 52px rgb(81, 85, 95), 53px 53px rgb(81, 85, 95), 54px 54px rgb(81, 85, 95), 55px 55px rgb(81, 85, 95), 56px 56px rgb(82, 85, 95), 57px 57px rgb(82, 86, 95), 58px 58px rgb(82, 86, 96), 59px 59px rgb(82, 86, 96), 60px 60px rgb(82, 86, 96), 61px 61px rgb(82, 86, 96), 62px 62px rgb(82, 86, 96), 63px 63px rgb(82, 86, 96), 64px 64px rgb(83, 87, 97);}
.flat-table {border-collapse:collapse;	font-family: 'Lato', Calibri, Arial, sans-serif; border:0!important;}
.flat-table tbody, .flat-table tfoot, .flat-table thead { border:0!important;}
.flat-table th {box-shadow: inset 0 -1px rgba(0,0,0,0.05),inset 0 1px rgba(0,0,0,0.05);	color:#fff!important; background: #2F333E; border:0!important; padding: 0.7em 1.25em 0.7em 1em;}
.flat-table td {box-shadow: inset 0 -1px rgba(0,0,0,0.05),inset 0 1px rgba(0,0,0,0.05);	color:#fff!important;	padding: 0.7em 1.15em 0.7em 1em;		text-shadow: 0 0 1px rgba(255,255,255,0.1);		font-size: 16px;}
.flat-table tbody tr, .flat-table tbody tr:nth-child(even) { background: #3E434C!important;}
.flat-table tbody tr:nth-child(2n) { background: #353A45!important; }
.flat-table td span.tablesaw-cell-content, .flat-table td:hover span.tablesaw-cell-content {background:transparent; }
.flat-table td span {background:#B86662; padding:3px 7px 5px; border-radius:5px;}
.flat-table td span:hover {background:#FD8875;}
.flat-table td span a{color:#fff; font-size: 12px; }
.flat-table td span a:hover{text-decoration: none;}
.tablesaw-stack tbody tr {border-bottom:0!important;}

.dedicated-servers-features {padding:50px 0; overflow:hidden;}
.dedicated-servers-features h2 {text-align: center; font-weight: 900}
.dedicated-servers-features p {text-align: center;}
.dedicated-servers-features ul {margin-top:50px;}
.dedicated-servers-features ul li {margin-bottom:30px;}
.dedicated-servers-features ul p {font-size:15px; font-weight:400; text-align: right}
.dedicated-servers-features ul h3 {font-size:18px; padding:11px 0; margin: 0 0 5px; font-weight:400; color:#000;}

/* Domain Names Search Box ------------------------ */
.domainsearch{ margin-top:45px; }
.domainsearch p { font-size:14px; color:#333; padding:5px 0; margin:0;}
.domainsearch form {padding: 10px;}
.domainsearch input[type="text"] { box-shadow: 3px 3px 1px #808080; font-size:18px; padding: 15px; height:65px; width:100%; -webkit-border-radius: 5px;-webkit-border-top-left-radius: 0;-webkit-border-bottom-left-radius: 0;-moz-border-radius: 5px;-moz-border-radius-topleft: 0;-moz-border-radius-bottomleft: 0;border-radius: 5px;border-top-left-radius: 0;border-bottom-left-radius: 0; }
.domainsearch select { box-shadow: 3px 3px 1px #808080; font-size:18px; padding: 15px;height:65px; background:#fff; width:100%; border-radius:0; border-right:1px solid #ccc;}
.domainsearch input[type="submit"] {box-shadow: 3px 3px 1px #808080; width:100%; float: right; font-size:16px; background: #65454c; height:65px; color:#fff; font-weight:900; padding:6px; text-transform: uppercase;  width: 100%; border-radius: 4px; border:0; cursor: pointer;  -ms-box-sizing: border-box;     -khtml-box-sizing: border-box;     -webkit-box-sizing: border-box;     -moz-box-sizing: border-box;    box-sizing: border-box;    box-sizing: border-box; -webkit-border-radius: 0;-webkit-border-top-left-radius: 5px;-webkit-border-bottom-left-radius: 5px;-moz-border-radius: 0;-moz-border-radius-topleft: 5px;-moz-border-radius-bottomleft: 5px;border-radius: 0;border-top-left-radius: 5px;border-bottom-left-radius: 5px;}
.domainsearch input[type="submit"]:hover { background: #b86662; }
.domainsearch select option span {color:#c00;}

.domain-prices {padding:50px 0; overflow:hidden;}
.domain-prices h2 {text-align: center; font-weight: 900}
.domain-prices p {text-align: center;}
.domainfeatures-list {padding:50px 0 70px; overflow:hidden; background:#F9F9F9;}
ul.domainfeatures {margin:0;}
ul.domainfeatures li {background:#F0F0F0; padding:20px; border:5px solid #F9F9F9;}
ul.domainfeatures p {font-size:15px; font-weight:400; text-align: right}
ul.domainfeatures h3 {font-size:18px; padding:11px 0; margin: 0 0 5px; font-weight:400; color:#000;}
ul.domainfeatures li i {color:#fff; font-size:54px;}
ul.domainfeatures li .circle {border-radius: 50%; overflow:hidden; width: 110px;	height: 110px; background:#bf6b65; padding-top:27px; margin:0 auto; position:relative; z-index:99999; text-align: center; }
.domain-prices .title-alt { background: #65454c;  padding: 12px 0;    text-align: center; letter-spacing: .07em;   color: #fff;    font-weight: 800;    font-size: 17px; }
.domain-prices .title-features { background: #b86662;  padding: 12px 0;    text-align: center; letter-spacing: .07em;   color: #fff;    font-weight: 800;    font-size: 17px; }
.pricing-table.domains .description {border-radius: 0;}
.pricing-table.domains.tld .bullet-item {  background-color: #A1A0A5; text-align: right;color: #fff; font-size:14px; border-bottom: solid 1px #959498; }
.pricing-table.domains .bullet-item {    background-color: #EDEDED;    padding: 0.9375em;    text-align: center;    color: #333;    font-size: 14px;    font-weight: normal;    border-bottom: solid 1px #dadada; border-right: solid 1px #dadada; }


/* Support ------------------------ */
.support-subheader {padding:50px 0; overflow:hidden;}
.support-subheader h2 {text-align: center; font-weight: 900}
.support-subheader p {text-align: center;}

.supportchannels {padding:0;}
.supportchannels h3 {font-size:21px; text-align: right}
.supportchannels p {text-align: right}
.timeline-content {padding:65px 0;}
.timeline-content .email.button {background:transparent; border:1px solid #DE6724; color:#DE6724; font-weight:700;}
.timeline-content .email.button:hover {background:#DE6724; color:#fff; text-decoration: none}
.timeline-content .chat.button {background:transparent; border:1px solid #589DA8; color:#589DA8; font-weight:700;}
.timeline-content .chat.button:hover {background:#589DA8; color:#fff; text-decoration: none}
.timeline-content .phone.button {background:transparent; border:1px solid #4F6366; color:#4F6366; font-weight:700;}
.timeline-content .phone.button:hover {background:#4F6366; color:#fff; text-decoration: none}
.timeline-content .ticket.button {background:transparent; border:1px solid #E2574C; color:#E2574C; font-weight:700;}
.timeline-content .ticket.button:hover {background:#E2574C; color:#fff; text-decoration: none}
.timeline-content .tweet.button {background:transparent; border:1px solid #4C9AD6; color:#4C9AD6; font-weight:700;}
.timeline-content .tweet.button:hover {background:#4C9AD6; color:#fff; text-decoration: none}
/*.line { border-right: 3px solid #DDDDDD; position: relative}*/
.line .roundimg {position: absolute; top:75px; left:40px; width:100%; max-width: 100%; height:auto;}
.line .roundimg img {border:7px solid #fff;}
.timeline-content img.imgpaddingright {padding-left:50px;}


/* Testimonials Page ------------------------ */
.testimonial-item .testimonial-text {background:#F0F0F0; margin-bottom:15px; padding:0 0 20px; -webkit-box-shadow: 1px 1px   0 rgba(0,   0,   0,  0.100),         3px 3px   0 rgba(255,255,255, 1.0),         4px 4px   0 rgba(0,   0,   0,  0.125),         6px 6px   0 rgba(255,255,255, 1.0),          7px 7px   0 rgba(0,   0,   0,  0.150),         9px 9px   0 rgba(255,255,255, 1.0),          10px 10px 0 rgba(0,   0,   0,  0.175);  -moz-box-shadow:         1px 1px   0 rgba(0,   0,   0,  0.100),         3px 3px   0 rgba(255,255,255, 1.0),         4px 4px   0 rgba(0,   0,   0,  0.125),         6px 6px   0 rgba(255,255,255, 1.0),          7px 7px   0 rgba(0,   0,   0,  0.150),         9px 9px   0 rgba(255,255,255, 1.0),          10px 10px 0 rgba(0,   0,   0,  0.175);  box-shadow:         1px 1px   0 rgba(0,   0,   0,  0.100),         3px 3px   0 rgba(255,255,255, 1.0),         4px 4px   0 rgba(0,   0,   0,  0.125),         6px 6px   0 rgba(255,255,255, 1.0),          7px 7px   0 rgba(0,   0,   0,  0.150),         9px 9px   0 rgba(255,255,255, 1.0),          10px 10px 0 rgba(0,   0,   0,  0.175);}
.testimonial-item .testimonial-text p {margin:0; padding:20px 20px 0; color:#333;text-transform: uppercase; font-size:14px;}
.testimonial-item .testimonial-text h6 {text-transform: uppercase; font-size:12px; line-height:1.4; font-weight: 900; color:#000; margin:17px 20px 0; padding:10px 0 0; letter-spacing: .07em; text-align: center; color:#fff; border-top:4px solid rgba(0,0,0,.1);}
.testimonial-item .testimonial-text h6 a {color:#000; }
.testimonial-item .testimonial-text h6 a:hover {color:#333; text-decoration: underline}

/* Typography ------------------------ */
.typography-subheader {padding:50px 0;}

/* Login ------------------------ */
.login-container {padding:2% 10%; overflow:hidden;}
.login-form {text-align: right; background:#fff; border: 0px solid #ebebeb;    border-radius: 5px; overflow:hidden;}
.login-form input[type="text"], .login-form input[type="email"], .login-form input[type="password"], .login-form input[type="tel"] { font-size:18px; padding: 10px; width:40%; border: 1px solid #d4d4d4;    -webkit-font-smoothing: antialiased;    -moz-osx-font-smoothing: grayscale;    -moz-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1); margin:8px 0 18px; }
.submit-login, .login-form input[type="submit"] { -moz-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1); width:100%; float: right; font-size:16px; background: #65454c; height:65px; color:#fff; font-weight:900; padding:6px; text-transform: uppercase;  width: 100%; border-radius: 4px; border:0; cursor: pointer;  -ms-box-sizing: border-box;     -khtml-box-sizing: border-box;     -webkit-box-sizing: border-box;     -moz-box-sizing: border-box;    box-sizing: border-box;    box-sizing: border-box; }
.login-form input[type="submit"]:hover { background: #37485C; }
.login-form label {float:right;width:15%}

/* Contact ------------------------ */
.contact-section {padding:50px 0;}
.contact-details h4 { color:#4B4854;margin-top:75px; font-size:18px; padding:0; letter-spacing:.07em; text-transform:uppercase; position: relative; font-weight:900;}
.contact-details ul { list-style: none; margin:0; padding:4px 0; }
.contact-details ul li {padding:7px 0; border-bottom:1px solid; border-color: #ecedee; font-weight:600; letter-spacing:.07em; text-transform:uppercase; font-size:12px; text-align: right}
.contact-details ul li span {display: block;}

.contact-details ul li:last-child { border-bottom:0;}
.contact-details ul li a { color:#303038}
.contact-details ul li a:hover { color:#b86662; text-decoration: none}
#gmap iframe {height: 450px; margin-bottom: 50px; width:100%;}
#sendstatus { margin: 0;}
#contactform input[type="text"] { font-size:15px; padding: 5px 10px; height:45px; width:100%; border: 1px solid #d4d4d4;    -webkit-font-smoothing: antialiased;    -moz-osx-font-smoothing: grayscale;    -moz-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1); margin:8px 0 18px; }
#contactform textarea { font-size:15px; padding: 5px 10px; width:100%; border: 1px solid #d4d4d4;    -webkit-font-smoothing: antialiased;    -moz-osx-font-smoothing: grayscale;    -moz-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1); margin:8px 0 18px; }
#contactform #submit { -moz-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1);    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1); width:100%; float: right; font-size:15px; background: #65454c; height:45px; color:#fff; font-weight:900; padding:6px; text-transform: uppercase;  width: 100%; border-radius: 4px; border:0; cursor: pointer;  -ms-box-sizing: border-box;     -khtml-box-sizing: border-box;     -webkit-box-sizing: border-box;     -moz-box-sizing: border-box;    box-sizing: border-box;    box-sizing: border-box; }
#contactform #submit:hover { background: #37485C; }

/* About Us ------------------------ */
.about-us-subheader {padding:50px 0; overflow:hidden;}
.about-us-subheader h2 {text-align: center; font-weight: 900}
.about-us-subheader p {text-align: center;}
.connect-with-us {margin:40px 0; padding:40px 0; background:#F0F0F0; border-top:1px solid #e8e8e8; border-bottom:1px solid #e8e8e8;}
.connect-with-us h2 {text-align: center; font-weight: 900;}
ul.connecticons {display:table; margin:0 auto; text-align: center; margin-top:45px;}
ul.connecticons li {padding: 0 45px;}
ul.connecticons li i {color:#fff; text-align: center; font-size:38px; width:90px; height:90px; background:#C5645B; border-radius:50%; padding:20px 0 0 0; border:6px solid #E2E2E2; -webkit-transition: all 0.4s ease; transition: all 0.4s ease; }
ul.connecticons li i:hover {background:#37485C; -webkit-transform:scale(1.2);transform:scale(1.2);}
.about-us-links {padding:0 0 50px; background:#fff; overflow:hidden;}
.about-us-links i {margin-bottom:10px; font-size:55px; color:#C5645B;}
.about-us-links ul {margin:40px 0;}
.about-us-links ul li {text-align: center; padding:35px 20px 30px; border:1px solid #E0E0E0; border-right:0; border-top:0; min-height:230px;}
.about-us-links ul li:nth-child(3n+3) { border-left:0;}
.about-us-links ul li:nth-last-child(-n+3) {border-bottom:0;}
.about-us-links ul li h3 {margin-bottom:12px; font-size:21px;}
.about-us-links .cta-button { text-align: center; padding: 8px 0; -webkit-border-bottom-left-radius: 8px;-webkit-border-bottom-right-radius: 8px;-moz-border-radius-bottomright: 8px;-moz-border-radius-bottomleft: 8px;border-bottom-right-radius: 8px;border-bottom-left-radius: 8px; }
.about-us-links .cta-button p { padding: 10px 0 6px; }
.about-us-links .cta-button span a {color:#C5645B; font-size:13px; font-weight:400; padding:6px 18px; border-radius: 6px; border:1px solid #C5645B;}
.about-us-links .cta-button span a:hover {color:#fff; text-decoration: none; background:#C5645B;}

/* FAQ ------------------------ */
.faq-subheader {padding:30px 0 50px; overflow:hidden;}
.faq-subheader h1 {font-weight: 900; font-size:32px; color:#C6C6C6;}
.faq-subheader .question {font-weight: 900; font-size:18px; color:#C66C66;}
.faq-subheader p {font-size:15px; margin: 17px 0 40px;  padding: 0 15px 0 0;  border-right: 5px solid #ECECEC;}
.faqcategory {padding:45px 0 20px; border-bottom:1px solid #ECECEC;}
.faqcategory:last-child {padding:45px 0 0; border-bottom:0;}



/* Infrastructure */
.infrastructure-subheader {padding:50px 0; overflow:hidden;}
.infrastructure-subheader h2 {text-align: center; font-weight: 900}
.infrastructure-subheader p {text-align: center;}
#map_wrapper { height: 550px; margin-top:25px;}
#map_canvas {width: 100%;    height: 100%;}
.info_content h5 {font-weight: 900; font-size:18px; color:#C66C66;}
.info_content p {text-align: right; font-size:15px;}

/* Infrastructure  Tabs */
.r-tabs .r-tabs-nav {  display: table; margin:0 auto; text-align: center;   padding: 0;}
.r-tabs .r-tabs-tab {    display: inline-block;    margin: 0;    list-style: none; }
.r-tabs .r-tabs-panel {    padding: 15px;    display: none;}
.r-tabs .r-tabs-accordion-title {    display: none;}
.r-tabs .r-tabs-panel.r-tabs-state-active {    display: block;}
/* Tabs container */
.r-tabs {   position: relative;   background-color: #fff;   border:0; }
.r-tabs .r-tabs-nav .r-tabs-tab {   position: relative;   background-color: #C66C66;}
.r-tabs .r-tabs-nav .r-tabs-anchor {   display: inline-block;  padding: 16px 30px;  text-decoration: none;   text-shadow: 0 1px rgba(0, 0, 0, 0.4);   font-size: 14px;   font-weight: bold;   color: #fff;}
/* Disabled tab */
.r-tabs .r-tabs-nav .r-tabs-state-disabled {   opacity: 0.5;}
/* Active state tab anchor */
.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {   color: #C66C66;   text-shadow: none;   background-color: #e8e8e8;}
/* Tab panel */
.r-tabs .r-tabs-panel { padding:20px 25px;   border-radius: 4px;   border-top:1px solid #e8e8e8; border-left: 1px solid #e8e8e8; border-right: 1px solid #e8e8e8; border-bottom: 4px solid #e8e8e8;}
/* Accordion anchor */
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {   display: block;   padding: 10px;   background-color: #C66C66;   color: #fff;   font-weight: bold;   text-decoration: none;
   text-shadow: 0 1px rgba(0, 0, 0, 0.4);   font-size: 14px; border-bottom: 1px solid #fff }
/* Active accordion anchor */
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {  background-color: #e8e8e8;   color: #C66C66;   text-shadow: none;}
/* Disabled accordion button */
.r-tabs .r-tabs-accordion-title.r-tabs-state-disabled {   opacity: 0.5;}

/* ==================
   6 = Order Slider
   ================== */
.pricing-order-slider {padding:40px 0; }
.pricing-order-slider h1 {color:#67484e; font-size:34px; font-weight:900; margin:8px 0 0; padding:75px 0 0; text-align:center;}
.pricing-order-slider li {border:0;}
.pricing-order-slider .centralized {text-align: center; padding:6px 15px 13px; margin:25px 0; background:#40546A; background:rgba(64,84,106,.85); border-radius:0; -webkit-box-shadow: 7px 7px 0px 0px rgba(55, 72, 92, 0.45);-moz-box-shadow:    7px 7px 0px 0px rgba(55, 72, 92, 0.45);box-shadow: 7px 7px 0px 0px rgba(55, 72, 92, 0.45);}
.pricing-order-slider h6 {font-size:15px;letter-spacing:.04em; color:#fff;font-weight:100;}
.pricing-order-slider .how_much {font-size:19px;color:#fff; font-weight:900; text-transform: uppercase; letter-spacing: -.03em;}
.ui-slider { position: relative; text-align: right; }
.ui-slider .ui-slider-handle { cursor: default; height: 1.2em; position: absolute; width: 1.2em; z-index: 2; }
.ui-slider .ui-slider-range { background-position:0 0; border: 0; display: block; font-size: .7em; position: absolute; z-index: 1; }
.ui-slider-horizontal { height: .8em; }
.ui-slider-horizontal .ui-slider-handle { margin-right: -.6em; top: -.3em; }
.ui-slider-horizontal .ui-slider-range { height: 100%; top: 0; }
.ui-slider-horizontal .ui-slider-range-min { right: 0; }
.ui-slider-horizontal .ui-slider-range-max { left: 0; }
.ui-slider-vertical { height: 100px; width: .8em; }
.ui-slider-vertical .ui-slider-handle { right: -.3em; margin-bottom: -.6em; margin-right: 0; }
.ui-slider-vertical .ui-slider-range { right: 0; width: 100%; }
.ui-slider-vertical .ui-slider-range-min { bottom: 0; }
.ui-slider-vertical .ui-slider-range-max { top: 0; }
.vps-prices-drag { float: right; margin: 0; padding: 45px 30px; width: 100%;  background:#40546A; background:rgba(64,84,106,.85); border-radius:0; -webkit-box-shadow: 7px 7px 0px 0px rgba(55, 72, 92, 0.45);-moz-box-shadow:    7px 7px 0px 0px rgba(55, 72, 92, 0.45);box-shadow: 7px 7px 0px 0px rgba(55, 72, 92, 0.45);}
.vps-prices-container {float: right; width: 100%; margin-top:50px;}
.vps-prices-panel { float: right; width: 100%;  }
.vps-prices-container #vps-slider { background: #bbbbbb; border-radius: 3px; float: right; height:7px; margin: 0 0 15px; width: 100%;  padding:0; box-shadow: 0 13px 16px rgba(0,0,0,0.16), 0 13px 16px rgba(0,0,0,0.23); cursor:pointer;}

.vps-prices-container #sliderlines { background: url(../images/lines1.png) center top; display: table; height: 10px; margin: 0 auto; width: 100%; opacity:.4;  }
.vps-prices-container #vps-slider .ui-slider-range { background:#CB6D63; border-radius: 3px; }
.vps-prices-container #vps-slider .ui-slider-handle { background: url(../images/slider_range.png) no-repeat right top; border: 0; border-radius: 0; height: 25px; margin-right: -15px; margin-top:-5px; outline: none; width: 25px; }
.vps-prices-container #vps-slider .ui-slider-handle:hover { cursor: pointer; }

#vps_name_option h3 span {font-size:18px; display:table; border-bottom:0; padding:10px 20px; margin:0 auto; margin-top:-12px; margin-bottom:20px; letter-spacing:.05em; color:#fff; background:#c44747; font-weight:900; position: relative;}
#vps_name_option h3 span:after {  bottom: 100%;    right: 50%;    border: solid transparent;    content: " ";    height: 0;    width: 0;    position: absolute;    pointer-events: none;    border-color: rgba(196, 71, 71, 0);    border-bottom-color: #c44747;    border-width: 10px;    margin-right: -10px;}

.total_amount h3 {text-align: center; }
.total_amount h3 span {font-size:45px; color:#fff; font-weight:900; letter-spacing: -.03em; }

a.order-vps { background:#40546A; background:rgba(64,84,106,.85); margin-bottom:70px; border-radius: 6px;}
a.order-vps:hover {background: #fff;color:#4b5462; text-decoration: none;}
/* End Order Slider - VPS Page */


/* Blog ---------------------- */
.sectionarea {padding:50px 0;overflow:hidden;}
.sectionarea.blog h2 {font-size:33px;padding:0; font-weight:900; margin:8px 0 28px; text-transform: uppercase;}
.sectionarea.blog h2 a {color:#4B4854;}
.sectionarea.blog h2 a:hover {color:#b86662; text-decoration: none}
.sectionarea.blog h3 {font-size:20px;padding:0; color:#666; font-weight:300; margin:10px 0; line-height: 1.3}
.sectionarea.blog .meta {letter-spacing:.07em; margin:10px 0 15px; padding:10px 2px 10px 0; color:#222D3A; border-top:1px solid #e8e8e8; border-bottom:4px solid #e8e8e8; font-size:13px; font-weight: 300;	text-transform: uppercase;}
.sectionarea.blog .meta i {padding:0 10px 0 5px; color:#222D3A;}
.sectionarea.blog .meta a {color:#b86662;}
.sectionarea.blog .entry p {margin-top:15px; color:#3D3F40; font-size:16px;}
.sectionarea.blog .button {background:#8C7379; color:#fff; font-weight:700; padding:8px 15px;}
.sectionarea.blog .button:hover {background:#b86662;; text-decoration: none}
.sectionarea.blog hr.separator { border: solid #e8e8e8; border-width: 1px 0 0; clear: both; margin: 40px 0; height: 0; }
#blogslider .item img{    display: block;    width: 100%;    height: auto;    }
ul.pagination {margin:0 auto; text-align: center; display:table; margin-top:50px;}
ul.pagination li { font-size:17px; }
ul.pagination li:hover a, ul.pagination li a:focus { text-decoration: none }
ul.pagination li.current a {  background: #b86662;}

/* widgetized areas sidebar  ---------------------- */
.sidebar {position: relative; padding:0 0 8px; margin:12px 0 5px;}
.sidebar #searchform {padding:15px 0 11px; margin:0;}
.sidebar #searchform input {background:#fff; border:1px solid #ccc; width:100%;  font-size:13px; color: #303038; height:28px;}
.sidebar #searchform button {background:#fff; margin-right:0; margin-top: 0; color:#333; padding: 6px 7px 5px; font-size:12px; -webkit-border-top-left-radius: 4px;-webkit-border-bottom-left-radius: 4px;-moz-border-radius-topleft: 4px;-moz-border-radius-bottomleft: 4px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;}
.sidebar .widget {margin-bottom:30px;}
.sidebar .textwidget {padding:4px 0; margin:0; line-height:1.4;}
.sidebar h4 {margin:0; padding:0;}
.sidebar h4.widget_title { color:#4B4854; background:#E8E8E8; font-size:13px; padding:9px 8px 8px; letter-spacing:.07em; text-transform:uppercase; position: relative; font-weight:900;}
.sidebar input {margin:0; padding: 0 8px; height:27px;}
.sidebar button {margin:0; padding:0; border:0;}
.sidebar form {margin:0; padding:0}
.sidebar select {background-color: white; font-size:12px; font-family: inherit; border: 1px solid #cccccc; color:#333; display: block; margin:0 auto; margin-top:12px; margin-bottom:12px; padding: 4px; width: 100%;}
.sidebar ul { list-style: none; margin:0; padding:4px 0; }
.sidebar ul li {padding:7px 0; border-bottom:1px solid; border-color: #ecedee; font-weight:600; letter-spacing:.07em; text-transform:uppercase; font-size:12px; text-align: right}
.sidebar ul li:last-child { border-bottom:0;}
.sidebar ul li a { color:#303038}
.sidebar ul li a:hover { color:#b86662; text-decoration: none}
.sidebar .portfoliowidget li {border-bottom:0; padding:3px;}
.sidebar .latestpostimg {float:right; width:25%;}
.sidebar .latestpostinfo {float:left; width:70%;}
.sidebar .latestpostinfo .latestposttitle {font-size:13px; color:#000; font-weight:bold; padding:4px 8px 10px 0; line-height:1.3;}
.sidebar .latestpostinfo .latestpostdate {font-size:10px; color:#999; padding-right:8px;}
.sidebar ul.children, .sidebar ul.sub-menu {padding-right:20px;}
.sidebar abbr {color:#999;}
.sidebar span a.rsswidget { color:#fff; padding-right:2px;}
.sidebar .rssSummary { padding-top:7px; line-height:1.3;}
.sidebar p.wp-caption-text {color:#666;}
.sidebar img.attachment-shop_thumbnail {max-width:50px; height:auto; float:left; padding-right:10px;}
.sidebar ul.product_list_widget li {overflow:hidden;}
.sidebar ul.product_list_widget li span.amount {display:block;}
.sidebar ul.product_list_widget li ins {display:block; background: none; text-decoration: none;}
.sidebar ul.product_list_widget li del span.amount  {display:none;}
.sidebar .screen-reader-text {display:none;}
.sidebar #wp-calendar {width:90%; margin:0 auto; margin-top:12px; margin-bottom:12px;}
.sidebar .tagcloud {padding: 17px 0 4px; overflow:hidden;}
.sidebar #searchform #searchsubmit {background:#b86662!important; cursor:pointer; width:100%!important; margin:0 auto!important; text-align: center!important; float:none!important; margin-top:4px!important; color:#fff!important; padding: 3px!important; font-size:12px!important; }
p.tags {margin-top: 12px;}
p.tags a {background:#5D5D65; color:#fff; padding:2px 6px 3px; border:none; font-size: 13px; text-decoration: none; margin-bottom:2px;  display: inline-block; }
p.tags a:hover {background:#b86662; color:#fff;}
.tagcloud {padding: 8px 0;}
.tagcloud a{position: relative; display: block; border: 0; float: right; padding: 5px 7px; margin: 0 0 3px 3px; color: #fff; background: #4B4854; font-size:13px !important;}
.tagcloud a:hover {position: relative;background: #b86662;border: 0; text-decoration: none; color:#fff;}

.single-section-container{padding-top:12px;}
.single-section-title{border-bottom:6px solid #E8E8E8; text-align:center; margin-bottom:23px;}
.single-section-title span.single-section-text{display:block; width:70px; padding:8px; background-color:#fff; margin:0px auto -17px;}
.sharing-buttons { padding:0; margin:0 auto; display:table;}
.sharing-buttons ul { list-style: none; margin:0; padding:0;  }
.sharing-buttons ul li { display:inline-block; padding:11px 7px 12px; margin:0;}
.sharing-buttons ul li i { color:#fff; text-align: center; font-size:16px; width:38px; height:38px; background:#b86662; border-radius:50%; padding:12px 0 0 0;}
.sharing-buttons ul li:hover i { background:#4B4854;}
.sharing-buttons ul li a {color:#fff; font-size:17px; }
.sharing-buttons ul li a:hover {color:#b86662; }


/* Author box */
.author-wrap {position: relative;  overflow:hidden; background:#fff;  border:6px solid #e8e8e8; padding:20px; margin:35px 0;  border-radius: 3px;}
.author-gravatar {margin: 0;line-height: 1;}
.author-gravatar img {border: 0;}
.author-title {color: #303038;display: block;line-height: 1.5;margin: 0 0 15px; padding: 0 0 10px; border-bottom:2px solid #e8e8e8;}
.author-title h6 {padding:0; margin: 0 0 6px;font-size: 18px; letter-spacing: .07em; font-weight: 900}
.author-title a {color: #000;text-decoration: none;}
.author-title a:hover, .author-title a:focus {color: #000;text-decoration: none;}
.author-description {display: block; margin: 0 0 5px;}
.author-description p {margin: 0; font-size: 16px}
.authorsocial ul { float:right; list-style: none; margin-top:17px; margin-right:0; padding:0; }
.authorsocial ul li { float:right; margin-left:5px;  font-size:11px;}
.authorsocial ul li a { padding:6px 8px; margin-top:-3px; color:#303038; background:#e8e8e8;}
.authorsocial ul li a:hover { background:#b86662; color:#fff; }

/* Comments */
.comments { background:#fff; border:1px solid #e8e8e8; border-radius: 3px;}
#thecomments {margin-top: 0;}
#thecomments h2.comment-title { color:#303038; background:#f1f3f5; font-size:16px; padding: 12px; position: relative; margin:0;}
#addcomments {margin-top: 0;}

/* Comments List */
ol.commentlist, ol.pingbacklist, .commentspagination, .nocomments, #addcomments { margin: 0;  padding: 0 20px;}
#addcomments {padding-bottom:10px; padding-top:10px;}
.commentsform {margin-top:35px; background:#fff; border:6px solid #e8e8e8; border-radius: 3px;}
.commentlist .comment-body{ padding: 0; margin: 0;  position: relative;    border-top: 0;}
.comment-body .reply{ margin-top:15px;}
.comment-body a:hover{  text-decoration: underline;}
.comment-body a.comment-reply-link {background:#b86662; margin: 0 12px 0 0; text-decoration:none; color:#fff; padding: 3px 8px 4px; font-size:11px; border:0; -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;}
.comment-body a.comment-reply-link:hover {  background: #000;}
ol.commentlist li, ol.pingbacklist li{  margin-top:20px;  list-style: none;    padding: 20px 0;     background:#fff;  border-bottom: 1px solid #e9e9e9;}
ol.commentlist li ul.children {margin-bottom: 0px !important;    margin-top: 25px !important;}
ol.commentlist li ul.children li{ border-bottom: 0; padding-right: 20px  !important; margin: 10px 0; border-right:1px solid #e8e8e8;}
ol.commentlist li ul.children comment-body{ padding: 15px 0 5px;  margin: 10px 0 0; position: relative;}
.reply {margin-bottom:25px;}
ol.commentlist img.avatar, ol.pingbacklist img.avatar { margin-left:20px; float:right; border-radius:50%; -webkit-border-radius:50%; -moz-border-radius:50%;}
ol.commentlist .comment-author, ol.pingbacklist .comment-author { line-height: 25px; border:0; width:100%; }
.comment-body cite.fn{  font-style: normal;   color: #000;}
.comment-time{  font-size: 12px;  font-style: normal;    color: #ccc;}
.commentmetadata {  clear: both;    padding: 0;    margin: 0;}
.commentmetadata a{ margin: 0;  color: #000;    text-decoration: underline;}
.comment-body p{  margin: 5px; padding:0 10px;}
.comment-body .comment-author.vcard{  margin: 0;}
.comment-body ul, .comment-body ol {padding:0; margin:0;}
.comment-body ul li, .comment-body ol li {padding:7px 0; margin:0; border:0;}
.comment-body ul li ul, .comment-body ol li ol {padding:7px 7px 7px 0; margin:0; border:0;}
#reply-title { margin:10px 0; padding:0 0 5px; font-weight:normal; font-size:26px; line-height:1.3; display: block; margin-bottom:-2px; font-weight: 700;}
form#commentform textarea {min-height:150px; border:1px solid #ccc;}
form#commentform input {border:1px solid #ccc;}
form#commentform input#submit, .wpcf7-submit {background:#b86662; margin-top: 0; color:#fff; padding: 6px 6px 7px; font-size:14px; border:0; -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;}
form#commentform input#submit:hover, .wpcf7-submit:hover {  background: #303038;}

/* ==================
   6 = Footer
   ================== */
footer {padding:50px 0 15px;  background:#222D3A;}
.contacts {padding-bottom:30px; color:#fff; text-align: center; font-size:14px;font-weight: 300; }
.contacts a {color:#fff;}
.contacts a:hover {text-decoration: none; color: #C5645B;}
.contacts i {display:block; font-size:33px; font-weight: 400; margin-bottom:12px;}

.footerlinks {padding:0;border-top:4px double #37485c;border-bottom:4px double #37485c; overflow:hidden;}
.footerlinks .border-right {border-left:1px solid #37485c;}
.footerlinks .columns {padding:20px 50px 20px 0;}
.footerlinks h2 {font-size:17px; color:#fff; padding:0; margin:0 0 20px; font-weight:900;}
.footerlinks ul {padding:0; margin:0; list-style:none;}
.footerlinks ul li {padding:4px 0; font-size:14px; color:#ccc;}
.footerlinks ul li a {color:#ccc;}
.footerlinks ul li a:hover {color:#C5645B; text-decoration: none}
.footerlinks p {color:#ccc; font-size:14px;}
.footerlinks hr { border: solid #494B4C; border-width: 7px 0 0; clear: both; margin: 11px 0; height: 0; }

/* MailChip Newsletter Form Styling  ----------------------- */
#mc-embedded-subscribe-form {padding:0!important; margin:0!important;}
#mc-embedded-subscribe-form input.email {width:100%!important;}
#mc-embedded-subscribe-form input.button {float: right; background: #37485C; margin-top:6px; color:#fff; font-size:12px; font-weight:900; padding:11px; text-transform: uppercase;  width: 100%!important; border-radius: 4px; border:0; cursor: pointer;  -ms-box-sizing: border-box;     -khtml-box-sizing: border-box;     -webkit-box-sizing: border-box;     -moz-box-sizing: border-box;    box-sizing: border-box;    box-sizing: border-box;}
#mc-embedded-subscribe-form input.button:hover { background: #C5645B; }

/* Social Links / Copyright Text  ----------------------- */
.social {margin-top:12px;}
.social ul {margin:0 1px;}
.social ul li {text-align: center; background:#2a3848; padding:6px 0; border-left:1px solid #222D3A; width:20%;}
.social ul li:last-child {border-left:0;}
.social ul li a {color:#fff;}
.social ul li a:hover {text-decoration: none;}
.social ul li.facebook:hover {background:#214379;}
.social ul li.twitter:hover {background:#42A4D7;}
.social ul li.googleplus:hover {background:#C65441;}
.social ul li.linkedin:hover {background:#1A96D5;}
.social ul li.pinterest:hover {background:#E4232F;}

p.copyright {font-size:12px; text-align:center; padding:35px 0 0; margin:0; color:#ccc; text-transform: uppercase;}

/* Back to Top Button  ----------------------- */
#back-to-top { color:#fff; position:fixed; bottom:20px; left:20px; z-index:99; display:none; text-align: center;	border-radius: 5px;	-moz-border-radius: 5px;	-webkit-border-radius: 5px;	-o-border-radius: 2px;    z-index: 10000;    height: 29px;    width: 29px;    background-color: rgba(0,0,0,0.4);    background-repeat: no-repeat;    background-position: center;    transition: background-color 0.1s linear;    -moz-transition: background-color 0.1s linear;    -webkit-transition: background-color 0.1s linear;    -o-transition: background-color 0.1s linear;}
#back-to-top i { padding-top:4px; font-size:17px; }
#back-to-top:hover { background:#B86662; background: rgba(184,102,98,.7); }



/* ==================
   7 = Media Queries
   ================== */
@media only screen and (min-width: 641px) {
   .logo-content {
      width: 25%;
   }
}
@media only screen and (max-width: 640px) {
	#navigation {display:none;}
	.slicknav_menu {display:block; font-size:13px; font-weight:500; background:#29303C; height: 0; padding: 0;}
	.slicknav_btn {top: 18px; right: 15px; z-index: 9; font-size:16px; background:#C5645B; border-radius:100%; width:34px; height:34px; padding:11px 8px 0 0; }
	.slicknav_nav a {text-transform: uppercase; font-size:13px; font-weight:500;}
	.slicknav_nav a:hover {-webkit-border-radius: 0!important; -moz-border-radius: 0!important; border-radius:0!important; background:#373f4b!important; color: #fff; }
header.alt-3.order-slider {background:url(../images/header_alt-3_bg.jpg) bottom center no-repeat; min-height:10px; background-size:cover; position: relative;}
header .message {top:100px;}
header.video .message, header.theslider .message {display:none;}
header.video .top, header.theslider .top {position: relative}
.top.sticky {position:relative; z-index:0}
#video-container, #slider-container {display: none}
.message-intro span.message-line{display: none}
.message-intro {padding-bottom:0;font-size:15px;letter-spacing: 0; text-align: center; width:100%; margin:0 auto; display:table;}
.message-intro p{text-align: center; margin:0 auto;}
header .message h1 {font-size:30px; margin:0;}
header .message .button {margin-top:30px;}
.logo {text-align: center}
.features ul li {border:0;}
.calltoaction .longshadow {font-size:39px;}
.calltoaction h2 {font-size:33px;  }
.testimonials h2 {font-size:33px;}
.whoclient span {font-size:12px;}
.testimonial-content p {padding:15px;}
.contacts .columns {margin-bottom:20px;border-bottom:1px double #37485c; padding-bottom:15px;}
.footerlinks {border: 0;}
.footerlinks .border-right {border-left:0;}
.footerlinks .columns {padding:0; margin-bottom:35px; text-align: center;}
.footerlinks h2 {margin:0 0 8px;}
.social {margin-top:0;}
.social ul li {border-left:0; margin-bottom:1px; width:100%;}
.pricing-table.alter .bullet-item i { margin-bottom:6px;}
.pricingboxes-comparison .title-alt { -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px;}
.pricing-table.alter li.bullet-item:first-child {  -webkit-border-top-left-radius: 8px;-webkit-border-top-right-radius: 8px;-moz-border-radius-topleft: 8px;-moz-border-radius-topright: 8px;border-top-left-radius: 8px;border-top-right-radius: 8px;}
.pricing-table.alter .cta-button {  -webkit-border-bottom-right-radius: 8px;-webkit-border-bottom-left-radius: 8px;-moz-border-radius-bottomright: 8px;-moz-border-radius-bottomleft: 8px;border-bottom-right-radius: 8px;border-bottom-left-radius: 8px; }
.dedicated-servers-features ul li img {display:block; margin:0 auto; text-align: center; padding-bottom:12px;}
.columns.hostingfeatures {display:none;}
.flat-table td span {width:100%; display:block; text-align: center}
.domainsearch{ margin-top:20px; }
.domainsearch input[type="text"] {font-size:15px; padding:6px; height:40px; border-radius: 5px; margin-bottom:6px;}
.domainsearch select { font-size:15px; height:40px;border-radius:5px;padding:6px; border-left:0;margin-bottom:6px;}
.domainsearch input[type="submit"] {font-size:14px; height:40px; border-radius: 5px;}
.domain-prices .title-alt { letter-spacing: 0; font-size: 11px; }
.domain-prices .title-features { letter-spacing: 0; font-size: 11px; }
.pricing-table.domains.tld .bullet-item { font-size:12px; }
.pricing-table.domains .bullet-item { padding: 0.65em; font-size: 12px;}
.timeline-content {padding:15px 0; text-align:center;}
.supportchannels h3 {text-align:center;}
.supportchannels p {text-align:center;}
.timeline-content img.imgpaddingright {padding-left:0; display: block; text-align: center;}
.supportchannels {padding:15px 0; border-bottom:3px solid #d7d7d7;}
ul.connecticons li {margin-bottom:15px;}
.about-us-links ul li {border:0;}
.faq-subheader h1 {font-size:24px;}
.r-tabs .r-tabs-nav { display: none; }
.r-tabs .r-tabs-accordion-title {  display: block;}
.flat-table td span.tablesaw-cell-content {text-align:right; padding-left:0; margin-top:8px; }
}

@media only screen and (min-width: 641px) and (max-width: 959px) {
.sf-menu a { font-size:13px; letter-spacing:0; padding:6px 7px 8px;}
.logo {margin-top:23px;}
.sf-menu li:hover ul,.sf-menu li.sfHover ul {top: 78px; width:135px;}
header .message .button {margin-top:30px;}
header .message {top:100px;}
header.video .message {top:25%;}
.message-intro {margin-bottom:20px}
header .message h1 {margin-top:0;}
.features ul li {min-height:315px;}
.features ul li h3 {margin-bottom:10px; font-size:18px;}
.features ul li p {font-size:15px;}
.footerlinks .columns {padding:20px 10px 20px 0;}
.pricing-table .price span {  font-size: 33px; }
.pricing-table.alter .price span {  font-size: 20px; }
.pricing-table .cta-button span a {font-size:14px; padding:9px 17px; border-radius: 6px}
.flat-table td span {display: block; text-align: center;}
.flat-table td, .flat-table th {font-size: 13px; padding: 0.7em;  }
.line .roundimg {left:30px;}
ul.domainfeatures li i {font-size:30px;}
ul.domainfeatures li .circle {width: 60px; height: 60px;padding-top:13px; }
.slidercaption { top: 30%;}
.slidercaption h3 { font-size:15px;margin-bottom:12px;}
.slidercaption p {font-size:12px; max-width:90%; padding:10px;}
.slidercaption .button {display:none;}
.faq-subheader h1 {font-size:24px;}
}

@media only screen and (min-width: 960px) and (max-width: 1025px) {
.line .roundimg {left:43px;}
.slidercaption { top: 23%;}
.slidercaption h3 { font-size:25px;margin-bottom:22px;}
.slidercaption p {font-size:14px; max-width:80%; padding:15px;}
.slidercaption .button {margin-top:0;padding:5px;}
}

@media only screen and (min-width: 1026px) and (max-width: 1441px) {
.slidercaption { top: 23%;}
.slidercaption h3 { font-size:25px;margin-bottom:22px;}
.slidercaption p {max-width:80%; padding:15px;}
}

@media only screen and (min-width : 768px) and (max-width : 1679px) {
.sf-menu li:hover ul,.sf-menu li.sfHover ul { -webkit-animation-name: fadeInUp;-moz-animation-name: fadeInUp; animation-name: fadeInUp;}
}


[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 -0.625rem; }
  [class*="block-grid-"]:before, [class*="block-grid-"]:after {
    content: " ";
    display: table; }
  [class*="block-grid-"]:after {
    clear: both; }
  [class*="block-grid-"] > li {
    display: block;
    float: right;
    height: auto;
    padding: 0 0.625rem 1.25rem; }

@media only screen {
  .small-block-grid-1 > li {
    list-style: none;
    width: 100%; }
    .small-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }

  .small-block-grid-2 > li {
    list-style: none;
    width: 50%; }
    .small-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }

  .small-block-grid-3 > li {
    list-style: none;
    width: 33.33333%; }
    .small-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }

  .small-block-grid-4 > li {
    list-style: none;
    width: 25%; }
    .small-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }

  .small-block-grid-5 > li {
    list-style: none;
    width: 20%; }
    .small-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }

  .small-block-grid-6 > li {
    list-style: none;
    width: 16.66667%; }
    .small-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }

  .small-block-grid-7 > li {
    list-style: none;
    width: 14.28571%; }
    .small-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }

  .small-block-grid-8 > li {
    list-style: none;
    width: 12.5%; }
    .small-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }

  .small-block-grid-9 > li {
    list-style: none;
    width: 11.11111%; }
    .small-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }

  .small-block-grid-10 > li {
    list-style: none;
    width: 10%; }
    .small-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }

  .small-block-grid-11 > li {
    list-style: none;
    width: 9.09091%; }
    .small-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }

  .small-block-grid-12 > li {
    list-style: none;
    width: 8.33333%; }
    .small-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }
@media only screen and (min-width: 40.0625em) {
  .medium-block-grid-1 > li {
    list-style: none;
    width: 100%; }
    .medium-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }

  .medium-block-grid-2 > li {
    list-style: none;
    width: 50%; }
    .medium-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }

  .medium-block-grid-3 > li {
    list-style: none;
    width: 33.33333%; }
    .medium-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }

  .medium-block-grid-4 > li {
    list-style: none;
    width: 25%; }
    .medium-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }

  .medium-block-grid-5 > li {
    list-style: none;
    width: 20%; }
    .medium-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }

  .medium-block-grid-6 > li {
    list-style: none;
    width: 16.66667%; }
    .medium-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }

  .medium-block-grid-7 > li {
    list-style: none;
    width: 14.28571%; }
    .medium-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }

  .medium-block-grid-8 > li {
    list-style: none;
    width: 12.5%; }
    .medium-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }

  .medium-block-grid-9 > li {
    list-style: none;
    width: 11.11111%; }
    .medium-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }

  .medium-block-grid-10 > li {
    list-style: none;
    width: 10%; }
    .medium-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }

  .medium-block-grid-11 > li {
    list-style: none;
    width: 9.09091%; }
    .medium-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }

  .medium-block-grid-12 > li {
    list-style: none;
    width: 8.33333%; }
    .medium-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }
@media only screen and (min-width: 64.0625em) {
  .large-block-grid-1 > li {
    list-style: none;
    width: 100%; }
    .large-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }

  .large-block-grid-2 > li {
    list-style: none;
    width: 50%; }
    .large-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }

  .large-block-grid-3 > li {
    list-style: none;
    width: 33.33333%; }
    .large-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }

  .large-block-grid-4 > li {
    list-style: none;
    width: 25%; }
    .large-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }

  .large-block-grid-5 > li {
    list-style: none;
    width: 20%; }
    .large-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }

  .large-block-grid-6 > li {
    list-style: none;
    width: 16.66667%; }
    .large-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }

  .large-block-grid-7 > li {
    list-style: none;
    width: 14.28571%; }
    .large-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }

  .large-block-grid-8 > li {
    list-style: none;
    width: 12.5%; }
    .large-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }

  .large-block-grid-9 > li {
    list-style: none;
    width: 11.11111%; }
    .large-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }

  .large-block-grid-10 > li {
    list-style: none;
    width: 10%; }
    .large-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }

  .large-block-grid-11 > li {
    list-style: none;
    width: 9.09091%; }
    .large-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }

  .large-block-grid-12 > li {
    list-style: none;
    width: 8.33333%; }
    .large-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

  	@media screen and (max-width: 0em), screen and (min-width: 40em) {
	.show-for-small {
	display: none !important; } }

.VuePagination ul.pagination li.active{
   background-color: #fff;
   border: 1px solid #b86662;
}
